<!--钥匙借还记录-->
<template>
  <div class="record_page">
    <!--record_container start-->
    <div class="record_container">
      <!--record_pos start-->
      <div class="record_pos">
        <router-link class="a" to="/personage/keyOther">钥匙管理</router-link>
        <span> > </span>
        <router-link class="a active" to="">借还记录</router-link>
      </div>
      <!--record_pos end-->
      <!--record_table start-->
      <div class="record_table">
        <div class="record_tr" v-for="(item, index) in list" :key="index">
          <div class="record_td">
            <div class="td"><span class="color-9">区域：</span>{{ item.fang_city }}</div>
            <div class="td"><span class="color-9">姓名：</span>{{ item.user_name }}</div>
          </div>
          <div class="record_td">
            <div class="td">
              <span class="color-9">门店：</span>{{ item.dian_name }}
            </div>
            <div class="td"><span class="color-9">工号：</span>124586</div>
          </div>
          <div class="record_td">
            <!-- <div class="td"><span class="color-9">经理：</span>孙佳琪</div> -->
            <div class="td">
              <span class="color-9">手机号：</span>{{ item.mobile }}
            </div>
          </div>
          <div class="record_td">
            <div class="td">
              <span class="color-9">领取时间：</span>{{ item.createtime }}
            </div>
            <div class="td">
              <span class="color-9">归还时间：</span>{{ item.updatetime }}
            </div>
          </div>
        </div>
      </div>
      <!--record_table end-->
      <div class="paging">
        <el-pagination
          background
          layout="prev, pager, next, jumper, ->, total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!--record_container end-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1, //页数
      total: 0, //条数
      list: [], //列表
      from:{
        fang_type:'',
        fang_id:'',
        page:1
      }
    };
  },
  methods:{
    getList () {
      this.$axios.keyJieList(this.from).then((res) => {
        this.list = res.data.data;
        this.total = res.data.total
      });
    }
  },
  created () {
    this.from.fang_type = this.$route.query.fang_type
    this.from.fang_id = this.$route.query.id
    this.getList()
  }
};
</script>

<style lang="less" scoped>
.record_container {
  background: #fff;
  padding: 30px 0px;
  .record_pos {
    padding: 0 30px;
    font-size: 14px;
    color: #666;
    display: flex;
    align-items: center;
    .a {
      color: #666;
    }
    .active {
      color: #3273f6;
    }
  }
  .record_table {
    display: table;
    width: 100%;
    margin: 20px 0px;
  }
  .record_tr {
    display: table-row;
  }
  .record_td {
    display: table-cell;
    padding: 10px 30px;
    font-size: 16px;
    line-height: 1.8;
  }
  .record_td .td {
    line-height: 1.8;
  }
  .record_tr:nth-child(2n) {
    background: #f7fcff;
  }
}
</style>
